<script lang="ts">
	import DropdownPanel from './DropdownPanel.svelte'
	import X from './icons/X.svelte'
	import { clickOutside, highlightText, slug } from '@lib/utils'
	import Search from './icons/Search.svelte'
	import ChevronRight from './icons/ChevronRight.svelte'
	import { products } from '@lib/products'
	import { blurMain } from '@stores/state'
	import config from 'src/config'

	let q = ''
	let searchOpen = false
	let el: HTMLElement
	let navSearchInput: HTMLInputElement

	function clearSearch() {
		q = ''
	}

	function openSearch() {
		searchOpen = !searchOpen
		if (searchOpen) {
			setTimeout(() => {
				navSearchInput?.focus()
			}, 100)
		}
		checkBlur()
	}

	function closeSearch() {
		searchOpen = false
		checkBlur()
	}

	function handleSearchEnter() {
		if (q.length) {
			window.location.href = `/products?q=${q}`
		}
	}

	function checkBlur() {
		// console.log('checkblur')
		try {
			if (!el?.checkVisibility()) return
			if (searchOpen) {
				document.getElementById('main')?.classList.add('blur-sm')
				// document.body?.classList.add('body-no-scroll')
			} else {
				document.getElementById('main')?.classList.remove('blur-sm')
				// document.body?.classList.remove('body-no-scroll')
			}
		} catch (e) {
			console.warn(e)
		}
	}

	$: results =
		q && q.length > 1
			? products.filter((i) => i.sku?.toLowerCase().includes(q.toLowerCase()))
			: []
</script>

<div
	class="relative block"
	bind:this={el}
	use:clickOutside
	on:outclick={() => closeSearch()}
>
	<button class="inline-flex items-center" on:click={openSearch}>
		Search
		<Search class="ml-2 h-4 w-4" />
	</button>
	<div class="relative">
		<DropdownPanel
			open={searchOpen}
			class="-right-16 top-6 mx-auto min-w-[380px] max-w-sm text-light"
		>
			<!-- <form
				method="GET"
				action="/products?q={q}#products"
				data-astro-reload
				class="flex w-full items-center justify-between"
			> -->
			<div class="flex w-full items-center justify-between">
				<Search class="mr-2 h-4 w-4 text-white" />
				<input
					bind:value={q}
					name="q"
					id="q"
					on:keydown={(e) => {
						if (e.key === 'Escape') {
							closeSearch()
						} else if (e.key === 'Enter') {
							handleSearchEnter()
						}
					}}
					bind:this={navSearchInput}
					placeholder="Search"
					class="h-full flex-1 border-none bg-transparent p-0 text-light text-white ring-0 focus:ring-0"
				/>
				<button class:hidden={!q} on:click={clearSearch}>
					<X class="h-4 w-4 text-white" />
				</button>
			</div>
			{#if results.length}
				<ul
					role="list"
					class="flex max-h-[280px] flex-col space-y-4 overflow-y-auto pt-1"
				>
					{#each results as { sku, brand }, idx}
						<li>
							<a
								href="/products/{slug(brand)}/{sku}"
								data-astro-reload
								class="flex w-full items-center focus-within:bg-gray-800"
							>
								<ChevronRight class="mr-2 h-4 w-4 text-white" />
								<span>{@html highlightText(sku, q)}</span>
							</a>
						</li>
					{/each}
				</ul>
			{/if}
			<div class="flex flex-col space-y-4 overflow-y-auto pt-4">
				<span class="ml-6 block text-white">Quick Links</span>
				{#each config.quickLinks as link}
					<a
						href={link.href}
						data-astro-reload
						class="flex w-full items-center"
					>
						<ChevronRight class="mr-2 h-4 w-4 text-white" />
						<span>{link.label}</span>
					</a>
				{/each}
			</div>
		</DropdownPanel>
	</div>
</div>
